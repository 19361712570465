import { LOGGEDIN_LINKS } from '@web/molecules';
import { FOOTER_CONTENT } from '../../testing/constants';

export const PET_LOCALSTORAGE_KEY = 'pet_values';
export const PLANS = [
  {
    confirmChangeSubtitle:
      'Cancelling will discard your changes and keep you on your current plan.',
    buttonLabel: 'Keep existing plan',
    planTypeLabel: 'CURRENT PLAN',
    amountOfPack: '{{packs}} packs per week',
    price: '{{price}}/WEEK',
    olliePlanTitle: 'Your recipes',
    recipes: 'Fresh Turkey, Fresh Chicken, Fresh Lamb, Baked chicken',
    deliveryFrequency: 'Every {{weeks}} weeks',
    deliveryTitle: 'Delivered',
    buttonVariant: 'lightest',
    isCurrentPlan: true,
  },
  {
    confirmChangeSubtitle:
      'Confirm changes and switch to this new recommended plan.',
    buttonLabel: 'Switch to {{planType}} plan',
    planTypeLabel: 'New plan',
    amountOfPack: '{{packs}} packs per week',
    price: '{{price}}/WEEK',
    olliePlanTitle: 'Your recipes',
    recipes: 'Fresh Turkey, Fresh Chicken, Fresh Lamb, Baked chicken',
    deliveryFrequency: 'Every {{weeks}} weeks',
    deliveryTitle: 'Delivered',
    buttonVariant: 'kale',
  },
];

export const HEADING_TEXT = 'Review your new subscription plan';
export const SUBTITLE =
  'Based on these changes, we recommend a new frequency and portion.';

export const HEADER_CONTENT = {
  links: LOGGEDIN_LINKS,
  logoUrl: '/',
  logoSrText: 'Ollie logo',
};

export const props = {
  headingText: HEADING_TEXT,
  subtitle: SUBTITLE,
  plans: PLANS,
  headerContent: HEADER_CONTENT,
  footerContent: FOOTER_CONTENT,
};
