'use client';
import styled from 'styled-components';

function getBackground(props) {
  if (props.checked) {
    return props.theme.colors.neutral.bone;
  }
  return props.theme.colors.background.base;
}

function getBackgroundOnHover(props) {
  if (!props.checked) {
    return props.theme.colors.background.base;
  } else {
    return getBackground(props);
  }
}

function getBorderColor(props) {
  if (props.checked) {
    return props.theme.colors.border.dark;
  }
  return props.theme.colors.neutral.bone;
}

function getBorderColorOnHover(props) {
  if (!props.checked) {
    return props.theme.colors.text.primary;
  } else {
    return getBorderColor(props);
  }
}

export const Button = styled.button`
  ${props => props.theme.typography.label}

  align-items: center;
  background: ${getBackground};
  border: 1px solid ${getBorderColor};
  display: inline-flex;
  margin: ${({ margin }) => margin};
  padding: 0 ${({ size }) => (size === 'small' ? '8px' : '20px')};
  user-select: none;
  color: ${props => props.theme.colors.text.primary};

  height: ${({ size }) => {
    if (size === 'small') {
      return '34px';
    }
    if (size === 'large') {
      return '56px';
    }
    return '48px';
  }};

  border-radius: ${({ size }) => {
    if (size === 'small') {
      return '16px';
    }
    if (size === 'large') {
      return '28px';
    }
    return '24px';
  }};

  &:hover {
    background: ${getBackgroundOnHover};
    border-color: ${getBorderColorOnHover};
  }

  &:focus {
    outline: 0;
    border: 2px solid ${props => props.theme.colors.text.primary};
    /* subtract 1px from the padding for the 2px border width */
    padding: 0 ${({ size }) => (size === 'small' ? '7px' : '19px')};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  margin-right: 11px;
`;

export const CloseIconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

export const Text = styled.div`
  margin-right: 10px;
  color: ${props => props.theme.colors.text.primary};
`;
