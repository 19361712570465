'use client';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useCombobox, useMultipleSelection } from 'downshift';
import useSWR from 'swr';
import { Icon } from '@ui/icons';
import { Chip } from '../Chip';
import {
  SearchContainer,
  SelectedItemWrapper,
  SearchInput,
  OpenButton,
  InputContainer,
  Menu,
  InputSizer,
  SearchLabel,
} from './styles';
import { MenuContent } from './MenuContent';

const removeWhitespace = searchInput => {
  const formattedInput = searchInput.trim();
  return formattedInput;
};

export const MultiSelect = ({
  searchLabel,
  name,
  values,
  selectedValues,
  // if there are featured values you need to filter out because you're using
  // another selection type (ie: AllergiesForm on Onboarding), please pass in
  // these featuredValues as an array. otherwise, you will receive all searchItems
  featuredValues = [],
  onChange,
  max,
  itemsToShow = {
    desktop: 6,
    mobile: 4,
  },
}) => {
  const { getSelectedItemProps, getDropdownProps, selectedItems } =
    useMultipleSelection({ selectedItems: selectedValues });

  const fetcher = useCallback(
    ([, term]) =>
      Array.isArray(values)
        ? values.filter(({ label }) => label.includes(term))
        : values(term),
    [values],
  );

  const [searchValue, setSearchValue] = useState('');

  const hideSearchLabel = selectedItems.length > 0 || searchValue !== '';

  const { data: searchItems = [], isValidating } = useSWR(
    [`MultiSelect:${name}`, removeWhitespace(searchValue)],
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const findCurrentValues = (currentValues, resultValue) => {
    return currentValues.find(currentValue => {
      if (currentValue.label === resultValue) return true;
      return false;
    });
  };

  const getFilteredItems = (items, currentValues) => {
    return items.filter(({ label }) => {
      if (!findCurrentValues(currentValues, label)) return true;
      return false;
    });
  };

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox({
    id: name,
    inputValue: searchValue,
    items: getFilteredItems(searchItems, selectedItems),
    itemToString: item => item?.label ?? '',
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setSearchValue('');
            onChange([...selectedItems, selectedItem]);
            selectItem(null);
          }
          break;
        default:
          break;
      }
    },
  });

  const getMinValue = ({ mobile, desktop }) => {
    return {
      mobile: Math.min(mobile, searchItems.length) || 1,
      desktop: Math.min(desktop, searchItems.length) || 1,
    };
  };

  const itemsToShowValues =
    typeof itemsToShow === 'number'
      ? getMinValue({ mobile: itemsToShow, desktop: itemsToShow })
      : getMinValue(itemsToShow);

  return (
    <>
      <SearchContainer>
        {getFilteredItems(selectedItems, featuredValues).map(
          (selectedItem, index) => (
            <SelectedItemWrapper
              key={selectedItem.value}
              {...getSelectedItemProps({ selectedItem, index })}
            >
              <Chip
                label={selectedItem.label}
                size="small"
                showIcon="delete"
                onClick={() =>
                  onChange(selectedItems.filter(item => item !== selectedItem))
                }
              />
            </SelectedItemWrapper>
          ),
        )}
        <InputContainer>
          <SearchInput
            {...getInputProps(
              getDropdownProps({
                preventKeyAction: isOpen,
                onKeyDown: event => {
                  if (selectedItems.length === 0 && event.key === 'Enter') {
                    event.preventDefault();
                  }
                },
                onChange: e => {
                  setSearchValue(e.target.value);
                },
              }),
            )}
          />
          <SearchLabel {...getLabelProps()} $hideSearchLabel={hideSearchLabel}>
            {searchLabel}
          </SearchLabel>
          <InputSizer>{searchValue}&nbsp;</InputSizer>
        </InputContainer>
        <OpenButton
          {...getToggleButtonProps()}
          type="button"
          aria-label="toggle menu"
        >
          <Icon
            iconName="chevron-down"
            alt="Open Menu"
            size={1}
            id="multiselect_open"
          />
        </OpenButton>
        <Menu
          {...getMenuProps()}
          $isOpen={isOpen}
          $itemsToShow={itemsToShowValues}
        >
          {isOpen && (
            <MenuContent
              items={getFilteredItems(searchItems, selectedItems)}
              selectedItems={selectedItems}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              searchLabel={searchLabel}
              searchValue={searchValue}
              max={max}
              isLoading={isValidating}
            />
          )}
        </Menu>
      </SearchContainer>
    </>
  );
};

const MultiSelectValue = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

MultiSelect.propTypes = {
  searchLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(MultiSelectValue),
    PropTypes.func,
  ]).isRequired,
  selectedValues: PropTypes.arrayOf(MultiSelectValue).isRequired,
  featuredValues: PropTypes.array,
  max: PropTypes.shape({
    count: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  itemsToShow: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      desktop: PropTypes.number.isRequired,
      mobile: PropTypes.number.isRequired,
    }),
  ]),
};
