import { isAfter, parse, subMonths } from 'date-fns';
import * as yup from 'yup';
import {
  FIELD_REQUIRED_MSG,
  NAME_REQUIRED_MSG,
  CURRENT_WEIGHT_REQUIRED_MSG,
  ACTIVITY_LEVEL_REQUIRED_MSG,
  SPAYED_STATUS_REQUIRED_MSG,
  BIRTH_YEAR_REQUIRED_MSG,
  BIRTH_MONTH_REQUIRED_MSG,
} from '../validation';
import {
  MAX_WEIGHT,
  MIN_WEIGHT,
  WEIGHT_ERROR,
  WEIGHT_FORMAT_ERROR,
} from './constants';

export const activitySchema = yup.object().shape({
  activity: yup.string().required(FIELD_REQUIRED_MSG),
});

export const allergiesSchema = yup.object().shape({
  allergies: yup.array(),
});

const youngestPup = subMonths(new Date(), 3);

export const birthDateSchema = yup.object({
  year: yup
    .string()
    .required(`Your pup's year of birth is required`)
    .notOneOf(['0'], `Your pup's year of birth is required`)
    .test(
      'is-not-too-young',
      'Sorry, we cannot accept pups less than 3mo old.',
      (year, { parent }) => {
        if (parent.month) {
          const date = parse(
            `${year}-${parent.month}-${parent.day || '01'}`,
            'yyyy-MM-dd',
            new Date(),
          );
          return isAfter(youngestPup, date);
        }
        return true;
      },
    ),
  month: yup
    .string()
    .required(`Your pup's month of birth is required`)
    .notOneOf(['0'], `Your pup's month of birth is required`),
  day: yup.string(),
});

export const breedsSchema = yup.object().shape({
  breeds: yup.array().max(2, 'Maximum of two breeds allowed'),
});

export const caloriesSchema = yup.object().shape({
  weight: yup
    .number()
    .required(FIELD_REQUIRED_MSG)
    .integer(WEIGHT_FORMAT_ERROR)
    .typeError(FIELD_REQUIRED_MSG)
    .max(MAX_WEIGHT, WEIGHT_ERROR)
    .min(MIN_WEIGHT, WEIGHT_ERROR),
  idealWeight: yup
    .number()
    .integer(WEIGHT_FORMAT_ERROR)
    .nullable()
    .typeError(FIELD_REQUIRED_MSG)
    .max(MAX_WEIGHT, WEIGHT_ERROR)
    .min(MIN_WEIGHT, WEIGHT_ERROR)
    .transform(v => (isNaN(v) ? null : v)),
  spayedStatus: yup
    .string()
    .required(FIELD_REQUIRED_MSG)
    .typeError(FIELD_REQUIRED_MSG),
});

export const pupInfoSchema = yup.object().shape({
  gender: yup.string().required(FIELD_REQUIRED_MSG),
  name: yup.string().required(FIELD_REQUIRED_MSG),
});

export const weightScreeningSchema = yup.object().shape({
  year: yup
    .string()
    .required(BIRTH_YEAR_REQUIRED_MSG)
    .notOneOf(['0'], BIRTH_YEAR_REQUIRED_MSG)
    .test(
      'is-not-too-young',
      'Sorry, we cannot accept pups less than 3mo old.',
      (year, { parent }) => {
        if (parent.month) {
          const date = parse(
            `${year}-${parent.month}-${parent.day || '01'}`,
            'yyyy-MM-dd',
            new Date(),
          );
          return isAfter(youngestPup, date);
        }
        return true;
      },
    ),
  month: yup
    .string()
    .required(BIRTH_MONTH_REQUIRED_MSG)
    .notOneOf(['0'], BIRTH_MONTH_REQUIRED_MSG),
  weight: yup
    .number()
    .required(CURRENT_WEIGHT_REQUIRED_MSG)
    .integer(WEIGHT_FORMAT_ERROR)
    .typeError(CURRENT_WEIGHT_REQUIRED_MSG)
    .max(MAX_WEIGHT, WEIGHT_ERROR)
    .min(MIN_WEIGHT, WEIGHT_ERROR),
  name: yup.string().required(NAME_REQUIRED_MSG),
  spayedStatus: yup
    .string()
    .required(SPAYED_STATUS_REQUIRED_MSG)
    .typeError(SPAYED_STATUS_REQUIRED_MSG),
  activity: yup.string().required(ACTIVITY_LEVEL_REQUIRED_MSG),
});
