'use client';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  margin: ${props => props.theme.spacing(3, 0, 12, 0)};
  gap: ${props => props.theme.spacing(4)};
  display: flex;
  flex-direction: column;
  @media ${props => props.theme.tablet} {
    margin-left: auto;
    margin-right: auto;
    width: 480px;
  }
`;

export const Label = styled.label`
  color: ${props => props.theme.colors.text.secondary};
  ${props => props.theme.typography.body};
`;

export const RadioCellContainer = styled.div`
  @media ${props => props.theme.tablet} {
    max-width: 360px;
  }
`;

export const WeightInputContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(4)};
`;

export const MultiSelectLabel = styled.label`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.secondary};
  padding-bottom: ${props => props.theme.spacing(2)};
  display: inline-block;
`;

export const StyledPupForm = styled.div`
  padding: ${props => props.theme.spacing(0, 4)};
`;
