import React from 'react';
import PropTypes from 'prop-types';
import { Item, PlaceholderMessageItem } from './styles';

export const MenuContent = ({
  items,
  selectedItems,
  getItemProps,
  highlightedIndex,
  searchLabel,
  searchValue,
  isLoading = false,
  max,
}) => {
  if (max && selectedItems.length >= max.count) {
    return <PlaceholderMessageItem>{max.message}</PlaceholderMessageItem>;
  }
  if (isLoading) {
    return <PlaceholderMessageItem>Loading...</PlaceholderMessageItem>;
  }
  if (items.length <= 0) {
    return searchValue.length > 0 ? (
      <PlaceholderMessageItem>
        Nothing found for "{searchValue}"
      </PlaceholderMessageItem>
    ) : (
      <PlaceholderMessageItem>{searchLabel}</PlaceholderMessageItem>
    );
  }
  return items.map((item, index) => (
    <Item
      key={item.value}
      {...getItemProps({ item, index })}
      $highlighted={highlightedIndex === index}
    >
      {item.label}
    </Item>
  ));
};

MenuContent.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  getItemProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
  searchLabel: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
  isLoading: PropTypes.bool,
  max: PropTypes.shape({
    count: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
  }),
};
