'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { LoadingView } from '@web/components';
import { useCurrentPet } from '../useCurrentPet';
import { RecommendNewPlanContainer } from '../RecommendNewPlanContainer';
import { EditPupContainer } from '../EditPupContainer';
import { useSessionCustomer } from '../../customer';
import { RedirectToLogin } from '../../router';
import { translateContentToProps } from '../translation';
import { HEADER_CONTENT } from '../RecommendNewPlanContainer/constants';
import { FOOTER_CONTENT } from '../../testing/constants';
import { props as pupProps } from '../EditPupContainer/constants';

export const EditPupFlowContainer = ({ content }) => {
  const router = useRouter();
  const { slug: slugs } = router.query;
  const activeView = slugs?.[0] || 'pup';
  const { pet, isLoading } = useCurrentPet();
  const { isLoading: isCustomerLoading, isActive } = useSessionCustomer();

  const cmsProps = translateContentToProps(content);

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isCustomerLoading || isLoading) {
    return <LoadingView />;
  }

  const stepsMap = {
    pup: (
      <EditPupContainer
        {...pupProps}
        onPupChanged={() =>
          router.push({ pathname: `/account/`, query: { pet_id: pet.id } })
        }
        onConflict={() =>
          router.push({
            pathname: `/account/edit-pup/new_plan`,
            query: { pet_id: pet.id },
          })
        }
      />
    ),
    new_plan: (
      <RecommendNewPlanContainer
        {...cmsProps.planSelectionInfo}
        headerContent={HEADER_CONTENT}
        footerContent={FOOTER_CONTENT}
        onSuccess={() =>
          router.push({
            pathname: '/account',
            query: { pet_id: pet.id },
          })
        }
        onKeepPlan={() =>
          router.push({
            pathname: '/account',
            query: { pet_id: pet.id },
          })
        }
        onGoBackClick={() =>
          router.push({
            pathname: '/account/edit-pup/',
            query: { pet_id: pet.id },
          })
        }
        onCancelClick={() => router.push('/account')}
      />
    ),
  };

  return <>{stepsMap[activeView]}</>;
};

EditPupFlowContainer.displayName = 'EditPupFlowContainer';

EditPupFlowContainer.propTypes = {
  content: PropTypes.object.isRequired,
};
