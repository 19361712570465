import { useCallback } from 'react';
import { useClient } from '../api';
import { ALLERGIES_URL } from './constants';

export { useAllergies } from './useAllergies';

export const useFetchAllergy = () => {
  const client = useClient();

  return useCallback(
    async term => {
      const searchPath = `${ALLERGIES_URL}?term=${term}`;
      const { data } = await client.get(searchPath);
      return data;
    },
    [client],
  );
};

export const useGetAllergies = () => {
  const fetchAllergy = useFetchAllergy();
  return useCallback(
    async term => {
      const results = await fetchAllergy(term);
      return results.map(({ name, id }) => ({ label: name, value: id }));
    },
    [fetchAllergy],
  );
};
