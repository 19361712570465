'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { interpolate } from '@format/interpolate';
import { Footer, LoadingView } from '@web/components';
import { modelToForm, formToModel } from '../../pets';
import { useReporter } from '../../reporter';
import { ApiResult, wrapErrorWithResult } from '../../api';
import { PupForm } from '../PupForm';
import { TOAST_SERVER_MESSAGE } from '../constants';
import { useToast, TOAST_CLIENT_ERROR_MESSAGE } from '../../Toast';
import { RedirectToLogin } from '../../router';
import { useSessionCustomer } from '../../customer';
import { AccountHeader } from '../AccountHeader';
import { useLocalStorage } from '../../hooks';
import { useCurrentPet } from '../useCurrentPet';
import { PET_LOCALSTORAGE_KEY } from './constants';
import {
  WrapperHeading,
  Heading,
  Subheading,
  Container,
  Content,
} from './styles';

export const EditPupContainer = ({
  heading,
  subheading,
  labelName,
  labelGender,
  labelBirth,
  labelBreeds,
  weightLabel,
  idealWeightLabel,
  idealWeightHelp,
  labelSpayed,
  labelAllergies,
  labelAllergySearch,
  labelActivityLevel,
  gender,
  spayed,
  activityLevels,
  labelButtonSave,
  labelButtonCancel,
  cancelUrl,
  footerContent,
  links,
  logoUrl,
  logoSrText,
  onPupChanged,
  onConflict,
}) => {
  const reporter = useReporter();
  const { pet, isLoading: petIsLoading, savePet } = useCurrentPet();
  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();
  const { showToast } = useToast();
  const [, setPetValues] = useLocalStorage(PET_LOCALSTORAGE_KEY, null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isCustomerLoading || petIsLoading) {
    return <LoadingView />;
  }

  const defaultValues = modelToForm(pet);

  const onSubmit = async form => {
    try {
      setIsSubmitting(true);
      const model = formToModel(form);
      await savePet(model);
      onPupChanged();
      showToast({
        status: 'success',
        message: 'Your pup’s info has been successfully updated',
        headline: 'Success',
      });
    } catch (err) {
      const result = wrapErrorWithResult(err);
      ApiResult.match(result, {
        error: {
          client: error => {
            reporter.error(error.original);
            showToast({
              status: 'error',
              message: TOAST_CLIENT_ERROR_MESSAGE,
              headline: 'Error Message',
            });
          },
          conflict: error => {
            setPetValues({
              ...form,
              recommendationVersion:
                error.original.response.data.recommendation_version,
            });
            onConflict();
          },
          server: () => {
            showToast({
              status: 'error',
              message: TOAST_SERVER_MESSAGE,
              headline: 'Error Message',
            });
          },
        },
      });
      ApiResult.ifError(result, () => setTimeout(() => setIsSubmitting(false)));
    }
  };

  return (
    <>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <Container>
        <Content>
          <WrapperHeading>
            <Heading>{interpolate(heading, { pupName: pet.name })}</Heading>
            <Subheading>{subheading}</Subheading>
          </WrapperHeading>
          <PupForm
            labelName={labelName}
            labelGender={labelGender}
            labelBirth={labelBirth}
            labelBreeds={labelBreeds}
            weightLabel={weightLabel}
            idealWeightLabel={idealWeightLabel}
            idealWeightHelp={idealWeightHelp}
            labelSpayed={labelSpayed}
            labelAllergies={labelAllergies}
            labelAllergySearch={labelAllergySearch}
            labelActivityLevel={labelActivityLevel}
            gender={gender}
            spayed={spayed}
            activityLevels={activityLevels}
            labelButtonSave={labelButtonSave}
            labelButtonCancel={labelButtonCancel}
            cancelUrl={cancelUrl}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            isSubmitting={isSubmitting}
          />
        </Content>
      </Container>
      <Footer {...footerContent} />
    </>
  );
};

EditPupContainer.displayName = 'EditPupContainer';

EditPupContainer.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  labelGender: PropTypes.string.isRequired,
  labelBirth: PropTypes.string.isRequired,
  labelBreeds: PropTypes.string.isRequired,
  weightLabel: PropTypes.string.isRequired,
  idealWeightLabel: PropTypes.string.isRequired,
  idealWeightHelp: PropTypes.string.isRequired,
  labelSpayed: PropTypes.string.isRequired,
  labelAllergies: PropTypes.string.isRequired,
  labelAllergySearch: PropTypes.string.isRequired,
  labelActivityLevel: PropTypes.string.isRequired,
  gender: PropTypes.array.isRequired,
  spayed: PropTypes.array.isRequired,
  activityLevels: PropTypes.array.isRequired,
  labelButtonSave: PropTypes.string.isRequired,
  labelButtonCancel: PropTypes.string.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  footerContent: PropTypes.object,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
  onPupChanged: PropTypes.func.isRequired,
  onConflict: PropTypes.func.isRequired,
};
