'use client';
import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing(3)};
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

export const MultiSelectContainer = styled.div`
  margin-top: ${props => props.theme.spacing(2)};
  text-align: left;
  width: 100%;
`;
