import { useCallback } from 'react';
import { useClient } from '../api';
import { BREEDS_URL } from './constants';

export { useBreeds } from './useBreeds';

export const useFetchBreeds = () => {
  const client = useClient();
  return useCallback(
    async term => {
      const searchPath = `${BREEDS_URL}${term ? `?term=${term}` : ''}`;
      const { data } = await client.get(searchPath);
      return data;
    },
    [client],
  );
};

export const useGetBreeds = () => {
  const fetchBreeds = useFetchBreeds();
  return useCallback(
    async term => {
      const data = await fetchBreeds(term);
      return data.map(({ name, id }) => ({ label: name, value: id }));
    },
    [fetchBreeds],
  );
};
