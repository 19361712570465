import { LOGGEDIN_LINKS, LOGO_URL, LOGO_SR_TEXT } from '@web/molecules';
import { FOOTER_CONTENT } from '../../testing/constants';

export const PET_LOCALSTORAGE_KEY = 'pet_values';

export const props = {
  heading: 'Edit {{pupName}}’s info',
  subheading:
    'Updating your pet’s information may change the plan we recommend for your pup. You can always choose to keep your existing plan.',
  labelName: 'Name',
  labelGender: 'Sex',
  labelBirth: 'Pup’s birthday (If you’re not sure, give us your best guess.)',
  labelBreeds: 'Breeds',
  labelSpayed: 'Neutered/Spayed?',
  labelAllergies: 'Allergies',
  weightLabel: 'Current weight',
  idealWeightLabel: 'Ideal weight',
  idealWeightHelp: 'Pup’s ideal weight in lbs (optional)',
  allergySearchLabel: 'Allergies',
  labelAllergySearch: 'Other (start typing)',
  labelActivityLevel: 'Activity Level',
  gender: [
    { name: 'Male', value: 'Male' },
    { name: 'Female', value: 'Female' },
  ],
  spayed: [
    { name: 'Yes', value: 'true' },
    { name: 'No', value: 'false' },
  ],
  activityLevels: [
    {
      value: 'Low',
      label: 'Mellow',
    },
    {
      value: 'Moderate',
      label: 'Active',
    },
    {
      value: 'High',
      label: 'Very Active',
    },
  ],
  labelButtonSave: 'Save changes',
  labelButtonCancel: 'Cancel',
  cancelUrl: '/account',
  footerContent: FOOTER_CONTENT,
  links: LOGGEDIN_LINKS,
  logoUrl: LOGO_URL,
  logoSrText: LOGO_SR_TEXT,
};
