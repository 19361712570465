'use client';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background.base};
`;

export const Content = styled.div`
  margin: auto;
  padding: ${({ theme }) => theme.spacing(0, 1)};

  @media ${props => props.theme.tablet} {
    max-width: 730px;
  }
`;

export const WrapperHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.spacing(12, 2, 12)};

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(12, 25)};
  }
`;

export const Heading = styled.h1`
  ${props => props.theme.typography.heading1}
  margin-bottom: ${props => props.theme.spacing(2)};
  text-align: center;
  @media ${props => props.theme.tablet} {
    ${props => props.theme.typography.headingMastiff}
  }
`;

export const Subheading = styled.h2`
  ${props => props.theme.typography.body}
  text-align: center;
`;
