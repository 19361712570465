import * as yup from 'yup';
import {
  birthDateSchema,
  breedsSchema,
  activitySchema,
  allergiesSchema,
  caloriesSchema,
  pupInfoSchema,
} from '../../pets/schema';

export const schema = yup
  .object()
  .concat(birthDateSchema)
  .concat(activitySchema)
  .concat(allergiesSchema)
  .concat(caloriesSchema)
  .concat(pupInfoSchema)
  .concat(breedsSchema);
