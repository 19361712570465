'use client';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background.base};
  padding-top: ${props => props.theme.spacing(12)};
  margin: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Subtitle = styled.p`
  ${props => props.theme.typography.heading5}
  text-align:center;
  margin: ${props => props.theme.spacing(10, 0)};
`;

export const RecommendNewPlanItems = styled.div`
  display: grid;
  gap: ${props => props.theme.spacing(12, 0)};
  justify-content: center;
  grid-template-columns: minmax(auto, 327px);
  @media ${props => props.theme.laptop} {
    grid-template-columns: minmax(auto, 327px) minmax(auto, 327px);
    gap: ${props => props.theme.spacing(0, 12)};
  }
`;

export const BakedCustomerMessageContianer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.base};
  padding: ${props => props.theme.spacing(5, 0, 10, 0)};
  margin: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(5, 0, 30, 0)};
  }
`;
