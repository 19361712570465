export const userViewRecommendPortionScreen = () => ({
  type: 'User viewed recommended portion screen',
});

export const userKeptExistingPlan = () => ({
  type: 'User kept existing plan',
});

export const userSwitchedPlan = () => ({
  type: 'User switched plan',
});
