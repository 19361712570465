import React from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { Tooltip } from '@web/atoms';
import questionIcon from './Question-Icon.svg';
import infoIcon from './Info-Icon.svg';
import { Trigger } from './styles';

export const TooltipIcon = ({
  children,
  name = 'more info',
  placement = 'left',
  variant = 'default',
  colorVariant,
}) => {
  const trigger = props => (
    <Trigger {...props}>
      <Image alt={name} src={variant === 'info' ? infoIcon : questionIcon} />
    </Trigger>
  );

  return (
    <Tooltip
      placement={placement}
      trigger={trigger}
      colorVariant={colorVariant}
    >
      {children}
    </Tooltip>
  );
};

TooltipIcon.displayName = 'TooltipIcon';

TooltipIcon.propTypes = {
  colorVariant: PropTypes.string,
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  placement: PropTypes.oneOf([
    'auto',
    'auto-end',
    'auto-start',
    'top',
    'top-end',
    'top-start',
    'right',
    'right-end',
    'right-start',
    'bottom',
    'bottom-end',
    'bottom-start',
    'left',
    'left-end',
    'left-start',
  ]),
  variant: PropTypes.oneOf(['default', 'info']),
  size: PropTypes.string,
};
