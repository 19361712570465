import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import { Button, IconContainer, CloseIconContainer, Text } from './styles';

const DEFAULT_ICON_SIZES = {
  small: '17px',
  medium: '20px',
  large: '20px',
};

export const Chip = ({
  checked = false,
  label,
  margin,
  onClick,
  showIcon,
  size = 'medium',
}) => {
  return (
    <Button
      type="button"
      onClick={onClick}
      margin={margin}
      size={size}
      checked={checked}
    >
      {showIcon === 'delete' && (
        <CloseIconContainer>
          <Icon
            iconName="close-circle"
            alt="delete icon"
            size={DEFAULT_ICON_SIZES[size]}
            id="chip_delete"
          />
        </CloseIconContainer>
      )}
      <Text>{label}</Text>
      {showIcon === 'check' && checked && (
        <IconContainer>
          <Icon
            iconName="check"
            alt="check icon"
            size={DEFAULT_ICON_SIZES[size]}
            color="text.secondary"
            id="chip_check"
          />
        </IconContainer>
      )}
    </Button>
  );
};

Chip.displayName = 'Chip';

Chip.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  showIcon: PropTypes.oneOf(['check', 'delete']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};
