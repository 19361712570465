'use client';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LinkButton } from '@ui/button';
import { xor } from 'lodash';
import { TooltipIcon, LoadingView } from '@web/components';
import { Cell, Grid, Input, Dropdown, Radio, MultiSelect } from '@web/atoms';
import { getYears, getMonths, getDayList } from '../../pets/functions';
import { useGetAllergies } from '../../allergies';
import { useCurrentPet } from '../useCurrentPet';
import { MultiSelectContainer } from '../../onboarding/AllergiesForm/styles';
import { editPupSave } from '../EditPupContainer/events';
import { useReporter } from '../../reporter';
import { useGetBreeds } from '../../breeds';
import { userAddsAllergens, userRemovesAllergens } from './events';
import { schema } from './schema';
import {
  MultiSelectLabel,
  ButtonWrapper,
  Label,
  RadioCellContainer,
  StyledPupForm,
  WeightInputContainer,
} from './styles';

export const PupForm = ({
  labelName,
  labelGender,
  labelBirth,
  labelBreeds,
  weightLabel,
  idealWeightLabel,
  idealWeightHelp,
  labelSpayed,
  labelAllergies,
  labelAllergySearch,
  labelActivityLevel,
  gender,
  spayed,
  activityLevels,
  labelButtonSave,
  labelButtonCancel,
  cancelUrl,
  onSubmit,
  onCancel,
  defaultValues,
  isSubmitting,
}) => {
  const getBreeds = useGetBreeds();
  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const [optionsYear, setOptionsYear] = useState([]);
  const [optionsMonth, setOptionsMonth] = useState([]);
  const [optionsDay, setOptionsDay] = useState([]);
  const reporter = useReporter();
  const { pet, isLoading: isPetLoading } = useCurrentPet();
  const getAllergies = useGetAllergies();
  const originalAllergies = defaultValues.allergies?.map(({ label }) => label);
  const refreshYearList = () => {
    const year = getYears();
    setOptionsYear(year);
  };

  const refreshMonthList = useCallback(() => {
    const yearValue = getValues('year');
    const month = getMonths(yearValue ?? 0);
    setOptionsMonth(month);
  }, [getValues]);

  const getFinalAllergies = useCallback(() => {
    const allergiesValue = getValues('allergies');
    return allergiesValue?.map(({ label }) => label);
  }, [getValues]);

  const refreshDayList = useCallback(() => {
    const [monthValue, yearValue] = getValues(['month', 'year']);
    const data = getDayList(yearValue ?? 0, monthValue ?? 0, '');
    setOptionsDay(data);
  }, [getValues]);

  useEffect(() => {
    refreshYearList();
    refreshMonthList();
    refreshDayList();
  }, [refreshMonthList, refreshDayList]);

  if (isPetLoading) {
    return <LoadingView />;
  }

  const idealWeightTooltip = (
    <TooltipIcon
      name="Display weight information"
      variant="info"
      placement="top"
    >
      {idealWeightHelp}
    </TooltipIcon>
  );

  const onFormSubmit = event => {
    const changedKeys = Object.keys(dirtyFields);
    const submittedAllergies = getFinalAllergies();

    const differences = xor(originalAllergies, submittedAllergies);

    const removedAllergies = differences.filter(a =>
      originalAllergies.includes(a),
    );
    const newAllergies = differences.filter(a =>
      submittedAllergies.includes(a),
    );

    const changes = Object.keys(defaultValues)
      .map(key => {
        return {
          [`edit_${key.toLowerCase()}`]: changedKeys.indexOf(key) !== -1,
        };
      })
      .reduce((obj, item) => ({ ...obj, ...item }));
    if (newAllergies.length)
      reporter.tag(userAddsAllergens(newAllergies.length, pet?.id ?? ''));
    if (removedAllergies.length)
      reporter.tag(
        userRemovesAllergens(removedAllergies.length, pet?.id ?? ''),
      );

    reporter.tag(editPupSave(changes));
    return handleSubmit(onSubmit)(event);
  };

  return (
    <StyledPupForm>
      <form onSubmit={onFormSubmit}>
        <Grid gap={{ row: 8, column: 4 }} columns={{ tablet: 2, mobile: 1 }}>
          <Cell columns={{ tablet: 2, mobile: 2 }}>
            <Controller
              control={control}
              name="name"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Input
                  {...field}
                  labelText={labelName}
                  status={error?.message && 'error'}
                  error={error?.message}
                />
              )}
            />
          </Cell>
          <Cell columns={{ tablet: 2, mobile: 2 }}>
            <MultiSelectLabel>{labelGender}</MultiSelectLabel>
            <RadioCellContainer>
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <>
                    <Radio
                      {...field}
                      choices={gender}
                      legendText={labelGender}
                    />
                  </>
                )}
                control={control}
                name="gender"
              />
            </RadioCellContainer>
          </Cell>
          <Cell columns={{ tablet: 2, mobile: 2 }}>
            <MultiSelectLabel>{labelBirth}</MultiSelectLabel>
            <Grid
              gap={{ row: 4, column: 4 }}
              columns={{ tablet: 3, mobile: 2 }}
            >
              <Cell columns={{ tablet: 1, mobile: 2 }}>
                <Controller
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Dropdown
                      {...field}
                      showLabel={false}
                      noSelectionLabel={'Year'}
                      label={''}
                      onChange={e => {
                        field.onChange(e);
                        refreshMonthList();
                      }}
                      selected={field.value?.toString() ?? ''}
                      error={error?.message}
                      options={optionsYear}
                    />
                  )}
                  control={control}
                  name="year"
                />
              </Cell>
              <Cell columns={{ tablet: 1 }}>
                <Controller
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Dropdown
                      {...field}
                      showLabel={false}
                      noSelectionLabel="Month"
                      label=""
                      onChange={e => {
                        field.onChange(e);
                        refreshDayList();
                        trigger('year');
                      }}
                      disabled={optionsMonth.length === 0}
                      selected={field.value?.toString() ?? ''}
                      error={error?.message}
                      options={optionsMonth}
                    />
                  )}
                  control={control}
                  name="month"
                />
              </Cell>
              <Cell columns={{ tablet: 1 }}>
                <Controller
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Dropdown
                      {...field}
                      showLabel={false}
                      noSelectionLabel="Day"
                      label=""
                      onChange={val => {
                        field.onChange(val);
                        trigger('year');
                      }}
                      disabled={optionsDay.length === 0}
                      selected={field.value?.toString() ?? ''}
                      error={error?.message}
                      options={optionsDay}
                    />
                  )}
                  control={control}
                  name="day"
                />
              </Cell>
            </Grid>
          </Cell>
          <Cell columns={{ tablet: 2, mobile: 2 }}>
            <Label>{labelBreeds}</Label>
            <Controller
              name="breeds"
              control={control}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <MultiSelectContainer>
                  <MultiSelect
                    {...field}
                    searchLabel={labelBreeds}
                    values={getBreeds}
                    selectedValues={field.value}
                    max={{ count: 2, message: 'Maximum of two breeds allowed' }}
                  />
                </MultiSelectContainer>
              )}
            />
          </Cell>
          <Cell columns={{ tablet: 2, mobile: 2 }}>
            <WeightInputContainer>
              <Controller
                control={control}
                name="weight"
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <Input
                    {...field}
                    type="number"
                    labelText={weightLabel}
                    value={field.value ?? ''}
                    status={error?.message ? 'error' : null}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="idealWeight"
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <Input
                    {...field}
                    type="number"
                    labelText={idealWeightLabel}
                    icon={idealWeightTooltip}
                    value={field.value ?? ''}
                    status={error?.message ? 'error' : null}
                    error={error?.message}
                  />
                )}
              />
            </WeightInputContainer>
          </Cell>
          <Cell columns={{ tablet: 2, mobile: 2 }}>
            <MultiSelectLabel>{labelActivityLevel}</MultiSelectLabel>
            <Controller
              control={control}
              name="activity"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Dropdown
                  {...field}
                  label={labelActivityLevel}
                  options={activityLevels}
                  showLabel={false}
                  selected={field.value}
                  noSelectionLabel=""
                  error={error?.message}
                />
              )}
            />
          </Cell>
          <Cell columns={{ tablet: 2, mobile: 2 }}>
            <MultiSelectLabel>{labelSpayed}</MultiSelectLabel>
            <RadioCellContainer>
              <Controller
                render={({ field: { ref, ...field } }) => (
                  <>
                    <Radio
                      {...field}
                      choices={spayed}
                      legendText={labelSpayed}
                    />
                  </>
                )}
                control={control}
                name="spayedStatus"
              />
            </RadioCellContainer>
          </Cell>
          <Cell columns={{ tablet: 2, mobile: 2 }}>
            <Label>{labelAllergies}</Label>
            <Controller
              control={control}
              name="allergies"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <MultiSelectContainer>
                  <MultiSelect
                    {...field}
                    selectedValues={field.value}
                    values={getAllergies}
                    searchLabel={labelAllergySearch}
                  />
                </MultiSelectContainer>
              )}
            />
          </Cell>
          <Cell columns={{ tablet: 2, mobile: 2 }}>
            <ButtonWrapper>
              <Button verticalPadding={4} disabled={isSubmitting} type="submit">
                {labelButtonSave}
              </Button>
              <LinkButton
                href={cancelUrl}
                onClick={onCancel}
                horizontalPadding={30}
                verticalPadding={4}
                variant="secondary"
                fullWidth
              >
                {labelButtonCancel}
              </LinkButton>
            </ButtonWrapper>
          </Cell>
        </Grid>
      </form>
    </StyledPupForm>
  );
};
PupForm.displayName = 'PupForm';
PupForm.propTypes = {
  labelName: PropTypes.string.isRequired,
  labelGender: PropTypes.string.isRequired,
  labelBirth: PropTypes.string.isRequired,
  labelBreeds: PropTypes.string.isRequired,
  weightLabel: PropTypes.string.isRequired,
  idealWeightLabel: PropTypes.string.isRequired,
  idealWeightHelp: PropTypes.string.isRequired,
  labelSpayed: PropTypes.string.isRequired,
  labelAllergies: PropTypes.string.isRequired,
  labelAllergySearch: PropTypes.string.isRequired,
  labelActivityLevel: PropTypes.string.isRequired,
  gender: PropTypes.array.isRequired,
  spayed: PropTypes.array.isRequired,
  activityLevels: PropTypes.array.isRequired,
  labelButtonSave: PropTypes.string.isRequired,
  labelButtonCancel: PropTypes.string.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  defaultValues: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
};
