'use client';
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { interpolate } from '@format/interpolate';
import { formatPrice } from '@format/currency';
import { Footer, LoadingView, NoRecipesFound } from '@web/components';
import { Heading } from '@web/atoms';
import { RecommendNewPlanForm } from '../RecommendNewPlanForm';
import { useSessionCustomer } from '../../customer';
import { useCurrentPet } from '../useCurrentPet';
import { formToModel } from '../../pets';
import { useReporter } from '../../reporter';
import { AccountHeader } from '../AccountHeader';
import {
  useProductRecommendations,
  useProduct,
  getNewPlanValues,
  determinePlanType,
  formatPlanValuesRecipes,
} from '../../products';
import {
  PRODUCT_TYPE_MIXED,
  PRODUCT_TYPE_FRESH,
  PRODUCT_TYPE_DRY,
  HALF_PORTION,
  FULL_PORTION,
} from '../../products/constants';
import { useSubscription } from '../../subscriptions';
import { useLocalStorage } from '../../hooks';
import { ApiResult } from '../../api';
import { TOAST_SERVER_MESSAGE } from '../constants';
import { NO_RECIPES_INFO } from '../AddPupFlowContainer/constants';
import { useToast, TOAST_CLIENT_ERROR_MESSAGE } from '../../Toast';
import { RedirectToLogin, Redirect } from '../../router';
import {
  Container,
  Subtitle,
  RecommendNewPlanItems,
  BakedCustomerMessageContianer,
} from './styles';
import {
  userViewRecommendPortionScreen,
  userKeptExistingPlan,
  userSwitchedPlan,
} from './events';
import { PET_LOCALSTORAGE_KEY } from './constants';

const showNoRecipeFound = ({ availableRecipes }) =>
  availableRecipes.fresh.length === 0 && availableRecipes.dry.length === 0;

export const RecommendNewPlanContainer = ({
  headingText,
  subtitle,
  plans,
  headerContent,
  footerContent,
  onSuccess,
  onKeepPlan,
  onGoBackClick,
  onCancelClick,
}) => {
  const reporter = useReporter();
  const { showToast } = useToast();
  const { pet, savePet, isLoading: petIsLoading } = useCurrentPet();
  const {
    customer,
    isActive,
    isLoading: customerIsLoading,
  } = useSessionCustomer();
  const [petValues, setPetValues] = useLocalStorage(PET_LOCALSTORAGE_KEY);
  const {
    subscription,
    isLoading: subscriptionIsLoading,
    updateSubscription,
  } = useSubscription(pet?.id);
  const { product, isLoading: isProductLoading } = useProduct(
    subscription?.sku,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const productRecommendations = useProductRecommendations({
    weight: petValues?.idealWeight || petValues?.weight,
    activity: petValues?.activity,
    neutered: petValues?.spayedStatus,
    allergies: petValues?.allergies?.map(({ value }) => value),
    recommendationVersion: petValues?.recommendationVersion,
  });
  const { mealPlanSku, productRecommendationsInfo } = useMemo(() => {
    if (product) {
      const { product_type, portion_size } = product;
      if (product_type === PRODUCT_TYPE_MIXED) {
        return {
          mealPlanSku: productRecommendations?.mixed?.recommended?.sku,
          productRecommendationsInfo: productRecommendations?.mixed,
        };
      } else if (
        product_type === PRODUCT_TYPE_FRESH &&
        portion_size === FULL_PORTION
      ) {
        return {
          mealPlanSku: productRecommendations?.fresh?.recommended?.sku,
          productRecommendationsInfo: productRecommendations?.fresh,
        };
      } else if (
        product_type === PRODUCT_TYPE_FRESH &&
        portion_size === HALF_PORTION
      ) {
        return {
          mealPlanSku: productRecommendations?.half_fresh?.recommended?.sku,
          productRecommendationsInfo: productRecommendations?.half_fresh,
        };
      } else if (product_type === PRODUCT_TYPE_DRY) {
        return {
          mealPlanSku: productRecommendations?.dry?.recommended?.sku,
          productRecommendationsInfo: productRecommendations?.dry,
        };
      }
    }
    return {};
  }, [product, productRecommendations]);

  const {
    product: recommendedProduct,
    isLoading: isRecommendedProductLoading,
  } = useProduct(
    mealPlanSku ?? productRecommendations?.fresh?.recommended?.sku, // fall back to Fresh when a pet switches to a cohort without a product of their given plan type
  );

  const isLoading =
    isProductLoading ||
    subscriptionIsLoading ||
    customerIsLoading ||
    petIsLoading ||
    isRecommendedProductLoading ||
    productRecommendations.isLoading;

  useEffect(() => {
    if (!isLoading) {
      reporter.tag(userViewRecommendPortionScreen());
    }
  }, [reporter, isLoading]);

  if (!customerIsLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  if (
    showNoRecipeFound(
      mealPlanSku ? productRecommendationsInfo : productRecommendations.fresh,
    )
  ) {
    return (
      <>
        <AccountHeader {...headerContent} name={customer.first_name} />
        <Container>
          <NoRecipesFound
            {...interpolate(NO_RECIPES_INFO, {
              pupName: pet.name,
            })}
            allergies={petValues.allergies}
            onClick={onGoBackClick}
            onSecondaryButtonClick={onCancelClick}
          />
        </Container>
        <Footer {...footerContent} />
      </>
    );
  }

  if (isEmpty(petValues)) {
    return (
      <Redirect
        replace
        to={{
          pathname: '/account/edit-pup',
          query: {
            pet_id: pet.id,
          },
        }}
      />
    );
  }

  const currentFrequency = product.weeks_of_food;
  const currentPortionSize = product.portion_size;
  const currentRecipes = {
    fresh: product.variants[0].recipes.fresh,
    dry: product.variants[0].recipes.dry,
  };
  const currentProductCalories = parseFloat(product.kcalories_per_day);
  const {
    newPricePerWeek,
    newRecipes,
    newTrayCount,
    newWeeksOfFood,
    newSku,
    newBagCount,
    newPortionSize,
    newProductCalories,
  } = getNewPlanValues(
    productRecommendations,
    currentFrequency,
    currentRecipes,
    recommendedProduct,
    currentPortionSize,
  );

  //TODO: Has to be updated with accurate dry variables when available.
  const planText = planValue => {
    const hasDry = planValue.isCurrentPlan
      ? product.variants[0].recipes.dry.length > 0
      : newRecipes.dry.length > 0;
    const hasFresh = planValue.isCurrentPlan
      ? product.variants[0].recipes.fresh.length > 0
      : newRecipes.fresh.length > 0;
    const hasMixed = hasFresh && hasDry;
    const halfPortion = planValue.isCurrentPlan
      ? product.portion_size === '0.5'
      : newPortionSize === '0.5';
    if (hasMixed) {
      return {
        olliePlanTitle: 'Mixed bowl',
        amountOfPack: `Mixed bowl: Fresh (${planValue.trayCount} packs), Baked (${planValue.numberOfBags} bags)`,
      };
    }
    if (hasFresh && halfPortion) {
      return {
        olliePlanTitle: 'Half fresh plan',
        amountOfPack: `Half fresh (${planValue.trayCount} packs)`,
      };
    }

    if (hasFresh) {
      return {
        olliePlanTitle: 'Full fresh plan',
        amountOfPack: `All fresh (${planValue.trayCount} packs)`,
      };
    }

    if (hasDry) {
      return {
        olliePlanTitle: 'All baked plan',
        amountOfPack: `All baked (${planValue.numberOfBags} bags)`,
      };
    }
  };
  const possiblePlans = plans.map(plan => {
    const planValues = plan.isCurrentPlan
      ? {
          sku: product.variants[0].sku,
          pricePerWeek: product.variants[0].price_per_week,
          recipes: formatPlanValuesRecipes(product.variants[0].recipes),
          planType: determinePlanType(product.variants[0].recipes),
          trayCount: product.variants[0].tray_count,
          numberOfBags: product.variants[0].bag_count,
          weeksOfFood: currentFrequency,
          isCurrentPlan: plan.isCurrentPlan,
          dailyCalories: currentProductCalories,
        }
      : {
          sku: newSku,
          pricePerWeek: newPricePerWeek,
          recipes: formatPlanValuesRecipes(newRecipes),
          planType: determinePlanType(newRecipes),
          trayCount: newTrayCount,
          numberOfBags: newBagCount,
          weeksOfFood: newWeeksOfFood,
          isCurrentPlan: plan.isCurrentPlan,
          dailyCalories: newProductCalories,
        };
    return {
      ...interpolate(plan, {
        packs: planValues.trayCount,
        planType: planValues.planType,
        price: formatPrice(planValues.pricePerWeek),
        weeks: planValues.weeksOfFood,
      }),
      ...planText(planValues),
      recipes: planValues.recipes,
      sku: planValues.sku,
      frequency: planValues.weeksOfFood,
      dailyCalories: `${planValues.dailyCalories} Calories/day`,
    };
  });
  const interpolatedHeaderText = interpolate(headingText, {
    pupName: petValues.name,
  });

  const updatePlan = async plan => {
    setIsSubmitting(true);
    reporter.tag(userSwitchedPlan());
    const updateSubscriptionResult = await updateSubscription({
      sku: plan.sku,
      frequency: plan.frequency,
      pet_id: pet.id,
    });
    const updatePetResult = await ApiResult.flatMapAsync(
      updateSubscriptionResult,
      () => ApiResult.callAsync(() => savePet(formToModel(petValues))),
    );
    ApiResult.match(updatePetResult, {
      success: () => {
        setPetValues(null, { skipRender: true });
        onSuccess();
      },
      error: {
        client: error => {
          reporter.error(error.original);
          showToast({
            status: 'error',
            message: TOAST_CLIENT_ERROR_MESSAGE,
            headline: 'Error Message',
          });
        },
        server: () => {
          showToast({
            status: 'error',
            message: TOAST_SERVER_MESSAGE,
            headline: 'Error Message',
          });
        },
      },
    });
    ApiResult.ifError(updatePetResult, () =>
      setTimeout(() => setIsSubmitting(false)),
    );
  };

  const onButtonClick = plan => {
    return async () => {
      if (plan.isCurrentPlan) {
        reporter.tag(userKeptExistingPlan());
        setPetValues(null, { skipRender: true });
        onKeepPlan();
        return;
      }
      return updatePlan(plan);
    };
  };

  return (
    <>
      <AccountHeader {...headerContent} name={customer.first_name} />
      <Container>
        <Heading
          headingText={interpolatedHeaderText}
          headingLevel="h1"
          position="center"
          typography="heading1"
        />
        <Subtitle>{subtitle}</Subtitle>
        <RecommendNewPlanItems>
          {possiblePlans.map((plan, index) => (
            <RecommendNewPlanForm
              key={index}
              {...plan}
              onButtonClick={onButtonClick(plan)}
              isSubmitting={isSubmitting}
            />
          ))}
        </RecommendNewPlanItems>
        <BakedCustomerMessageContianer />
      </Container>
      <Footer {...footerContent} />
    </>
  );
};

RecommendNewPlanContainer.displayName = 'RecommendNewPlanContainer';
RecommendNewPlanContainer.propTypes = {
  headingText: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  plans: PropTypes.array.isRequired,
  headerContent: PropTypes.object.isRequired,
  footerContent: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  onKeepPlan: PropTypes.func.isRequired,
  onGoBackClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};
